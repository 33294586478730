import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Backlink from "../components/back-link"
import SEO from "../components/seo"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const tl = useRef(null)

  useEffect(() => {
    tl.current = gsap.timeline({})

    gsap.utils.toArray(".gatsby-resp-image-image").forEach((image, i) => {
      tl.current.from(image, {
        x: -300,
        ease: "none",
        scrollTrigger: {
          trigger: image,
          scrub: true,
          end: "bottom +=90%",
        },
      })
    })

    return () => {
      if (tl.current) {
        tl.current.kill()
      }
    }
  }, [])

  return (
    <Layout>
      <SEO
        title={frontmatter.uberschrift}
        description={excerpt}
        image={frontmatter.einleitungsbild.publicUrl}
      />
      <div className="blog-post lg:py-12 max-w-content flex flex-col items-center mx-auto min-h-screen overflow-hidden">
        <h1 className="max-w-full mt-6 mb-16 px-4 lg:px-0 min-w-0 break-words hyphen text-center">
          {frontmatter.uberschrift}
        </h1>
        <div className="max-w-content mx-auto flex flex-col items-center px-4 lg:px-0 mb-20">
          <div className="" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <Backlink />
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        uberschrift
        datum
        einleitungsbild {
          publicURL
        }
      }
    }
  }
`
